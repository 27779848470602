.App {
    width: 100%;

    /*border-width: 2px;
    border-color: green;
    border-style: dashed;*/

    display: block;
    overflow: auto;    
}

.app-header-table {
    background-color: #393939;
    display: table;  
}

.cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center; /* the key */
}

.logo {
    margin: 0px 20px;
}

.floatLeft { float: left; }
.floatRight { float: right; }
.center { text-align: center; }

.margin14 { margin:14px 14px; }
.mailto { color: #929292; text-decoration:none; }
.divMain { margin: 20px; }

.width100 { width: 100%; }

.content {
    display: inline-block; /* important !! */
}

.nowrap {
    white-space: nowrap;
}
.margin020 {
    margin: 0px 20px;
}

@media all and (min-width: 480px) {
    .Login {
        padding: 60px 0;
    }
  
    .Login form {
        margin: 0 auto;
        max-width: 320px;
    }
}

.brdr {
    border-width: 2px;
    border-color: green;
    border-style: dashed;
}

disabledCheckBox {
    color: #ccc;
}

.page-header {
    padding-bottom: 9px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #eee;
}