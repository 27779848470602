body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-align-left {
    text-align: left;
 }

.modal-view-summary {
  border-radius: 5px;
  padding: 20px;
  width: 400px;
}

.table>tbody>tr>td, .table>tbody>tr>th {
  border-top: none;
}
.checkbox {
    margin-top: 0px;
    margin-bottom: 0px;
}

.selected { background-color:#2f96b4 !important; }

.red { background-color:#ffeeee !important; }

.modal-quest-uploaded {
    border-radius: 5px;
    padding: 20px;
    width: 750px;
}
.modal-isic-tree {
    border-radius: 5px;
    padding: 20px;
    width: 90vw;    /* Occupy the 90% of the screen width */
    max-width: 90vw;
}
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important;
    height: 750px;
}
.table>tbody>tr>td, .table>tbody>tr>th {
    border-top: none;
}
.table-row {
    display: table-row;
    width: "100%";
}
.table-cell {
    display: table-cell;
    padding: "2px";
}
.error {
    color: #D8000C;
}
.invalid {
    box-shadow: 0 0 3px #CC0000;
}

.my-modal {
    width: 90vw;    /* Occupy the 90% of the screen width */
    max-width: 90vw;
} 

.modal-edit-event {
    border-radius: 5px;
    padding: 20px;
    width: 750px;
}

.container-border {
    border-width: 2px;
    border-color: red;
    border-style: dashed;
  }
.checkbox {
    margin-top: 0px;
    margin-bottom: 0px;
}

.red { background-color:#ffeeee !important; }

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important;
    height: 750px;
}

.modal-view-file {
    border-radius: 5px;
    padding: 20px;
    width: 750px;
}

.imgScale {
    width : 710px;
    height: auto;/*to preserve the aspect ratio of the image*/
    margin-top: 30px;
}
.modal-edit-pressrelease {
    border-radius: 5px;
    padding: 20px;
    width: 750px;
}

.App {
    width: 100%;

    /*border-width: 2px;
    border-color: green;
    border-style: dashed;*/

    display: block;
    overflow: auto;    
}

.app-header-table {
    background-color: #393939;
    display: table;  
}

.cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center; /* the key */
}

.logo {
    margin: 0px 20px;
}

.floatLeft { float: left; }
.floatRight { float: right; }
.center { text-align: center; }

.margin14 { margin:14px 14px; }
.mailto { color: #929292; text-decoration:none; }
.divMain { margin: 20px; }

.width100 { width: 100%; }

.content {
    display: inline-block; /* important !! */
}

.nowrap {
    white-space: nowrap;
}
.margin020 {
    margin: 0px 20px;
}

@media all and (min-width: 480px) {
    .Login {
        padding: 60px 0;
    }
  
    .Login form {
        margin: 0 auto;
        max-width: 320px;
    }
}

.brdr {
    border-width: 2px;
    border-color: green;
    border-style: dashed;
}

disabledCheckBox {
    color: #ccc;
}

.page-header {
    padding-bottom: 9px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #eee;
}
