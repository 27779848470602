.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    height: 750px;
}
.table>tbody>tr>td, .table>tbody>tr>th {
    border-top: none;
}
.table-row {
    display: table-row;
    width: "100%";
}
.table-cell {
    display: table-cell;
    padding: "2px";
}
.error {
    color: #D8000C;
}
.invalid {
    box-shadow: 0 0 3px #CC0000;
}

.my-modal {
    width: 90vw;    /* Occupy the 90% of the screen width */
    max-width: 90vw;
} 

.modal-edit-event {
    border-radius: 5px;
    padding: 20px;
    width: 750px;
}

.container-border {
    border-width: 2px;
    border-color: red;
    border-style: dashed;
  }