.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    height: 750px;
}

.modal-view-file {
    border-radius: 5px;
    padding: 20px;
    width: 750px;
}

.imgScale {
    width : 710px;
    height: auto;/*to preserve the aspect ratio of the image*/
    margin-top: 30px;
}